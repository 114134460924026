// Implementation of WAI-ARIA tabs pattern (https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-automatic/)

/**
 * Initializes WAI-ARIA tabs by setting up each tablist.
 */
export default function initTabs() {
  const tablists = document.querySelectorAll('[role="tablist"]');
  tablists.forEach(tablist => {
    initTablist(tablist);
  });
}

/**
 * @param { HTMLElement } tablist Tablist to initialize the tabs for
 */
function initTablist(tablist) {
  const tabs = tablist.querySelectorAll('[role="tab"]');
  if(tabs.length === 0) {
    return;
  }

  const tabpanels = getAllTabpanels(tabs);
  if(tabpanels.length === 0) {
    return;
  }

  tabs.forEach(tab => initTabEventListeners(tab, tabs, tabpanels));

  setSelectedTab(tabs[0], tabs, tabpanels, true);
}

/**
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @returns { HTMLElement[] } Array of tabpanels
 */
function getAllTabpanels(tabs) {
  const tabpanelIds = Array.from(tabs).map(tab => tab.getAttribute('aria-controls'));
  return document.querySelectorAll(`#${tabpanelIds.join(', #')}`);
}

/**
 * @param { HTMLElement } tab Tab to add event listeners to
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @param { HTMLElement[] } tabpanels All tabpanels respective to the tabs
 */
function initTabEventListeners(tab, tabs, tabpanels) {
  tab.addEventListener('click', () => setSelectedTab(tab, tabs, tabpanels));
  tab.addEventListener('keydown', (event) => handleTabKeys(event, tab, tabs, tabpanels));
}

/**
 * @param { HTMLElement } selectedTab Tab to select
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @param { HTMLElement[] } tabpanels All tabpanels respective to the tabs
 * @param { boolean } initialSelection Whether or not it is the initial selection on page load
 */
function setSelectedTab(selectedTab, tabs, tabpanels, initialSelection = false) {
  if(selectedTab.getAttribute('aria-selected') === 'true') {
    return;
  }

  tabs.forEach(tab => {
    tab === selectedTab
      ? handleSelectedTab(tab, initialSelection)
      : handleNonSelectedTab(tab);
  });

  showTabpanelForSelectedTab(selectedTab, tabpanels);
}

/**
 * @param { HTMLElement } selectedTab Selected tab
 * @param { HTMLElement[] } tabpanels All tabpanels respective to the tabs
 */
function showTabpanelForSelectedTab(selectedTab, tabpanels) {
  const selectedTabpanelId = selectedTab.getAttribute('aria-controls');
  tabpanels.forEach(tabpanel => {
    tabpanel.classList.toggle('hidden', tabpanel.id !== selectedTabpanelId);
  });
}

/**
 * @param { HTMLElement } tab Tab to set as selected
 * @param { boolean } initialSelection Whether or not it is the initial selection on page load
 */
function handleSelectedTab(tab, initialSelection = false) {
  tab.setAttribute('aria-selected', 'true');
  tab.removeAttribute('tabindex');
  if(!initialSelection) {
    tab.focus();
  }
}

/**
 * @param { HTMLElement } tab Tab to set as not selected
 */
function handleNonSelectedTab(tab) {
  tab.setAttribute('aria-selected', 'false');
  tab.setAttribute('tabindex', '-1');
}

/**
 * @param { KeyboardEvent } event Keyboard event to handle
 * @param { HTMLElement } selectedTab Currently selected tab
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @param { HTMLElement[] } tabpanels All tabpanels respective to the tabs
 */
function handleTabKeys(event, selectedTab, tabs, tabpanels) {
  let index = -1;

  switch (event.key) {
    case 'ArrowLeft':
      index = getPreviousTabIndex(selectedTab, tabs);
      break;

    case 'ArrowRight':
      index = getNextTabIndex(selectedTab, tabs);
      break;

    case 'Home':
      index = 0;
      break;

    case 'End':
      index = tabs.length - 1;
      break;
  }

  if(index !== -1) {
    setSelectedTab(tabs[index], tabs, tabpanels);

    event.stopPropagation();
    event.preventDefault();
  }
}

/**
 * @param { HTMLElement } currentTab Currently selected tab
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @returns { number } Index of the previous tab
 */
function getPreviousTabIndex(currentTab, tabs) {
  const currentTabIndex = Array.from(tabs).findIndex(tab => tab === currentTab);
  return currentTabIndex === 0 ? tabs.length - 1 : currentTabIndex - 1;
}

/**
 * @param { HTMLElement } currentTab Currently selected tab
 * @param { HTMLElement[] } tabs All tabs in tablist
 * @returns { number } Index of the next tab
 */
function getNextTabIndex(currentTab, tabs) {
  const currentTabIndex = Array.from(tabs).findIndex(tab => tab === currentTab);
  return currentTabIndex === tabs.length - 1 ? 0 : currentTabIndex + 1;
}

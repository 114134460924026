import { addScrollGradient, addHorizontalScrollButton, scrollToPosition } from './utils/scroll';

/**
 * Initializes the carousel listing.
 */
export default function initCarouselListning() {
  const carouselContainers = document.querySelectorAll('.carousel-listing-block');

  if(carouselContainers.length > 0) {
    carouselContainers.forEach((container) => {
      const tagButtons = container.querySelectorAll('.tag-button');
      const pageCarousels = container.querySelectorAll('.page-carousel');

      tagButtons.forEach(button => {
        button.addEventListener('click', function() {
          handleTagButtonClick(button, pageCarousels);
        });
      });

      pageCarousels.forEach(pageCarousel => {
        initPageCarouselScroll(pageCarousel);
      });
    });
  }
}

/**
 * @param { HTMLElement } button The clicked tag button
 * @param { NodeList } pageCarousels The collection of page carousel blocks.
 */
function handleTagButtonClick(button, pageCarousels) {
  const selectedTag = button.getAttribute('aria-controls');

  pageCarousels.forEach(pageCarousel => {
    if(matchesSelectedTag(pageCarousel, selectedTag)) {
      const scrollContainer = pageCarousel.querySelector('.carousel-listing-wrapper ul');
      scrollToPosition(scrollContainer, 0, { direction: 'horizontal', scrollBehaviour: 'auto'});
    }
  });
}

/**
 * Checks if the page carousel matches the selected tag.
 * @param {HTMLElement} block - Block with tags.
 * @param {string} selectedTag - The selected tag.
 * @returns {boolean} True if there is a match, false otherwise.
 */
function matchesSelectedTag(block, selectedTag) {
  const blockTags = getCarouselBlockTags(block);
  return blockTags.some(tag => selectedTag === tag);
}

/**
 * Gets the categories from the listing block or category from the item.
 * @param {HTMLElement} block - Carousel container.
 * @returns {Array} An array of tags.
 */
function getCarouselBlockTags(block) {
  const blockTags = block.getAttribute('id');
  return blockTags ? blockTags.split(',') : [];
}

/**
 * Initializes the scroll for the page carousel.
 * @param {HTMLElement} pageCarousel - Container for the page carousel.
 */
function initPageCarouselScroll(pageCarousel) {
  const carouselListingWrapper = pageCarousel.querySelector('.carousel-listing-wrapper');
  if(!carouselListingWrapper) {
    return;
  }

  const scrollContainer = carouselListingWrapper.querySelector('ul');
  addScrollGradient(scrollContainer, carouselListingWrapper);
  addHorizontalScrollButton(scrollContainer, carouselListingWrapper);
  addScrollGradient(scrollContainer, carouselListingWrapper, { position: 'start' });
  addHorizontalScrollButton(scrollContainer, carouselListingWrapper, { position: 'start' });
}

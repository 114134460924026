import { addScrollGradient, scrollToElement } from './utils/scroll';

/**
 * Initializes the submenu by adding scroll gradient and scrolling to the selected item.
 */
export default function initSubMenu() {
  const submenu = document.querySelector('.sub-menu');
  if(!submenu){
    return;
  }

  const submenuScroll = submenu.querySelector('ul');
  addScrollGradient(submenuScroll, submenu, { direction: 'horizontal' });
  scrollToSelectedSubMenuItem();
}

/**
 * Scrolls to the selected item in the submenu.
 */
function scrollToSelectedSubMenuItem() {
  const submenuScroll = document.querySelector('.sub-menu ul');
  const selectedItem = submenuScroll.querySelector('.selected');

  const { marginLeft } = window.getComputedStyle(selectedItem);
  const scrollMargin = Number(marginLeft.replace('px', '')) + 8;

  scrollToElement(submenuScroll, selectedItem, { direction: 'horizontal', scrollOffset: scrollMargin, scrollBehaviour: 'auto' });
}
